/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types';
import React from 'react';
import { Translate } from 'react-redux-i18n';
import styled from 'styled-components';

const LinkStyleButtonText = styled(Translate)`
  color: var(--ui-link-default-navy-600, #0a57a4);
  text-align: center;
  font-family: 'Helvetica Neue';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
`;

const Button = styled.button`
  display: block;
  width: 100%;
  margin-bottom: 8px;

  @media (min-width: 769px) {
    width: auto;
  }
`;

const SubmitButtonText = ({ applicantPay, submitting, price }) => {
  if (submitting) {
    return <Translate value='buttons.submitting' />;
  }

  if (applicantPay) {
    return (
      <span>
        <i className='fa fa-credit-card' />{' '}
        <Translate
          value='buttons.applicantPaySubmit'
          amount={parseFloat(price / 100)}
        />
      </span>
    );
  }

  return <Translate value='buttons.submit' />;
};

SubmitButtonText.propTypes = {
  applicantPay: PropTypes.bool,
  submitting: PropTypes.bool,
  price: PropTypes.number,
};

const SubmitButton = ({
  applicantPay,
  disabled,
  submitting,
  price,
  pageName,
}) => (
  <Button
    type='submit'
    className='btn btn-success pull-right'
    disabled={disabled}
    data-buttonid={`submit_${pageName}`}
    data-testid={`submit_${pageName}`}
  >
    <SubmitButtonText {...{ submitting, applicantPay, price }} />
  </Button>
);

SubmitButton.propTypes = {
  applicantPay: PropTypes.bool,
  disabled: PropTypes.bool,
  submitting: PropTypes.bool,
  price: PropTypes.number,
  pageName: PropTypes.string,
};

const PreviousButton = ({ onClick, disabled, pageName }) => {
  return (
    <Button
      className='btn btn-secondary pull-left border-0'
      onClick={onClick}
      disabled={disabled}
      type='button'
      data-buttonid={`previous_${pageName}`}
      data-testid={`previous_${pageName}`}
    >
      <LinkStyleButtonText value='buttons.back' />
    </Button>
  );
};

PreviousButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  pageName: PropTypes.string,
};

const ReloadPageButton = ({ pageName }) => {
  return (
    <Button
      className='btn btn-secondary pull-left border-0'
      onClick={() => {
        window.location.reload();
      }}
      type='button'
      data-buttonid={`reload_${pageName}`}
      data-testid={`reload_${pageName}`}
    >
      <LinkStyleButtonText value='buttons.restartThisOrder' />
    </Button>
  );
};

ReloadPageButton.propTypes = {
  pageName: PropTypes.string,
};

export const NextButton = ({ disabled, pageName }) => {
  return (
    <Button
      type='submit'
      className='btn btn-primary pull-right'
      disabled={disabled}
      data-buttonid={`continue_${pageName}`}
      data-testid={`continue_${pageName}`}
    >
      <Translate value='buttons.continue' /> <i className='fa fa-angle-right' />
    </Button>
  );
};

NextButton.propTypes = {
  disabled: PropTypes.bool,
  pageName: PropTypes.string,
};

export const EScreenButton = ({ disabled, pageName }) => {
  return (
    <Button
      type='submit'
      className='btn btn-primary pull-right'
      disabled={disabled}
      data-buttonid={`contactEScreen_${pageName}`}
      data-testid={`contactEScreen_${pageName}`}
      style={{ backgroundColor: '#168838' }}
    >
      <Translate value='buttons.contactEScreen' />{' '}
      <i className='fa fa-angle-right' />
    </Button>
  );
};

EScreenButton.propTypes = {
  disabled: PropTypes.bool,
  pageName: PropTypes.string,
};

const IncorrectInformationButton = ({ onClick }) => (
  <Button
    onClick={onClick}
    id='incorrect-information'
    className='btn btn-secondary pull-left'
    type='button'
  >
    <Translate value='buttons.incorrectInformation' />
  </Button>
);

IncorrectInformationButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export const Navigation = ({
  applicantPay,
  currentPage,
  page,
  handlePreviousPage,
  formIsBusy,
  price,
  totalPages,
  protectedInvitationFlow,
  partialSsnEntered,
  partialSsnRequired,
  handleIncorrectInformation,
  submitting,
  hidePreviousButton,
  hideContinueButton,
}) => {
  const authenticationRequired =
    protectedInvitationFlow && !partialSsnEntered && partialSsnRequired;

  return (
    <div>
      {page.name === 'Custody & Control Form' && (
        <>
          <ReloadPageButton pageName={page.name} />
          <EScreenButton
            disabled={authenticationRequired || formIsBusy}
            pageName={page.name}
          />
        </>
      )}
      {currentPage > 1 &&
        !protectedInvitationFlow &&
        !hidePreviousButton &&
        page.name !== 'Custody & Control Form' && (
          <PreviousButton
            onClick={handlePreviousPage}
            disabled={formIsBusy}
            pageName={page.name}
          />
        )}
      {page.name === 'confirmInformation' && (
        <IncorrectInformationButton onClick={handleIncorrectInformation} />
      )}
      {page.name === 'welcome' && protectedInvitationFlow && (
        <IncorrectInformationButton onClick={handleIncorrectInformation} />
      )}
      {currentPage === totalPages ? (
        <SubmitButton
          applicantPay={applicantPay}
          disabled={formIsBusy}
          submitting={submitting}
          price={price}
          pageName={page.name}
        />
      ) : (
        !hideContinueButton &&
        page.name !== 'Custody & Control Form' && (
          <NextButton
            disabled={authenticationRequired || formIsBusy}
            pageName={page.name}
          />
        )
      )}
    </div>
  );
};

Navigation.propTypes = {
  applicantPay: PropTypes.bool,
  protectedInvitationFlow: PropTypes.bool,
  currentPage: PropTypes.number.isRequired,
  handlePreviousPage: PropTypes.func.isRequired,
  formIsBusy: PropTypes.bool.isRequired,
  page: PropTypes.object.isRequired,
  price: PropTypes.number,
  totalPages: PropTypes.number.isRequired,
  handleIncorrectInformation: PropTypes.func.isRequired,
  partialSsnEntered: PropTypes.bool,
  partialSsnRequired: PropTypes.bool,
  submitting: PropTypes.bool,
  hidePreviousButton: PropTypes.bool,
  hideContinueButton: PropTypes.bool,
};

export default Navigation;
